<!--
 * @Author: Dyf
 * @Date: 2023-03-13 09:24:13
 * @Descripttion:  消息详情
-->
<style lang="scss" scoped>
.notice-detail {
    position: relative;
    @include innerPage(40px 0 48px);

    .el-button {
        position: absolute;
        bottom: 30px;
        left: 30px;
    }

    .detail {
        &-inner {
            width: 100%;
            height: calc(100% - 50px);
            box-sizing: border-box;
            padding-left: 12px;
            padding-right: 34px;
            margin-bottom: 40px;
            overflow: hidden;
            overflow-y: auto;

            h3 {
                line-height: 38px;
                color: #232323;
                text-align: center;
            }

            .other-info {
                height: 30px;
                font-size: 16px;
                color: #696969;
                margin-top: 8px;
                @include flexBox(center);

                span {
                    margin: 0 20px;
                }
            }
        }

        &--rich {
            width: 1046px;
            margin: 20px auto 0;

            ::v-deep {
                p {
                    font-size: 16px;
                    line-height: 30px;
                    text-align: justify;
                    color: #434444;
                }

                img {
                    display: block;
                    max-width: 100%;
                    border-radius: 10px;
                    margin: 36px auto;
                }
            }
        }

        &--foot {
            height: 62px;
            padding-top: 32px;
            line-height: 24px;
            font-size: 16px;
            color: #1d1d1d;
            border-top: 1px solid #dfe0e6;
            @include flexBox;

            p {
                cursor: pointer;

                &:hover {
                    color: #6c4ecb;
                }

                margin-right: 48px;
            }
        }
    }
}
</style>

<template>
    <section class="notice-detail">
        <el-button type="custom_primary" size="small" @click="$router.go(-1)">
            返回
        </el-button>
        <div class="detail-inner">
            <h3 class="bold">{{ detailData.plsch_mes_title }}</h3>
            <div class="other-info pf_medium">
                <span>{{ detailData.create_time | formatTime }}</span>
            </div>
            <div class="detail--rich" v-html="detailData.plsch_mes_content" />
        </div>
    </section>
</template>

<script>
import { $notifyDetail } from "@api/home"
import { mapActions } from "vuex"
export default {
    name: 'home_noticeDetail',
    data() {
        return {
            detailData: {},
        }
    },
    created() {
        this.getDetail();
        this.getMsgReadStatus();
    },
    methods: {
        ...mapActions('user', ['getMsgReadStatus']),
        /** 消息详情 */
        async getDetail() {
            let { data } = await $notifyDetail(this.$route.params.id);
            this.detailData = data;
            this.$forceUpdate();
        },
    },
}
</script>